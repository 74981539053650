@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf");
}

@layer base {
  html {
    @apply font-manrope;
    @apply bg-dark;
  }
}

.animate-shake {
  animation: shake 1s;
}

.embed-container {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.expand-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.preview-container {
  width: 100%;
  height: 500px;
}

@media (max-width: 1200px) {
  .embed-container {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 800px) {
  .embed-container {
    width: 100%;
    height: 300px;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}